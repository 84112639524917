var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c("c-table", {
            ref: "grid",
            attrs: {
              title: "정비이력",
              columns: _vm.grid.columns,
              data: _vm.grid.data,
              isTitle: true,
              gridHeightAuto: true,
              filtering: false,
              columnSetting: false,
              usePaging: false,
              hideBottom: true,
            },
            on: { linkClick: _vm.linkClick },
            scopedSlots: _vm._u([
              {
                key: "customArea",
                fn: function ({ props, col }) {
                  return [
                    col.name === "woWorkPlanStepCd"
                      ? [
                          _c(
                            "q-chip",
                            {
                              class:
                                props.row.woWorkPlanStepCd == "WWPSC00000"
                                  ? "blinking"
                                  : "",
                              attrs: {
                                color: _vm.getColors(
                                  props.row.woWorkPlanStepCd
                                ),
                                "text-color": "white",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(props.row.woWorkPlanStepName) + " "
                              ),
                            ]
                          ),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c("c-table", {
            ref: "grid",
            attrs: {
              title: "점검이력",
              columns: _vm.grid2.columns,
              data: _vm.grid2.data,
              isTitle: true,
              gridHeightAuto: true,
              filtering: false,
              columnSetting: false,
              usePaging: false,
              hideBottom: true,
            },
            scopedSlots: _vm._u([
              {
                key: "customArea",
                fn: function ({ props }) {
                  return [
                    props.row["checkStatusCd"]
                      ? [
                          _c(
                            "q-chip",
                            {
                              class:
                                props.row.checkStatusCd === "MCSC000020"
                                  ? "blinking"
                                  : null,
                              attrs: {
                                color: _vm.setTagColor(props.row.checkStatusCd),
                                clickable: true,
                                "text-color": "white",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.btnClicked(props.row)
                                },
                              },
                            },
                            [
                              props.row.checkStatusCd === "MCSC000020"
                                ? _c("q-avatar", {
                                    attrs: {
                                      icon: "alarm",
                                      color: "red",
                                      "text-color": "white",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.setTagName(props.row.checkStatusCd)
                                  ) +
                                  " "
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      _c("div", { staticClass: "popup-bottom-bar" }, [
        _c(
          "div",
          { staticClass: "popup-bottom-bar-close" },
          [
            _c("q-btn", {
              attrs: { flat: "", color: "gray", icon: "arrow_back" },
              on: { click: _vm.closePopUps },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }